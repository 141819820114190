define("trunk/models/user", ["exports", "ember-data", "ember-api-actions", "trunk/mixins/models/changeable"], function (_exports, _emberData, _emberApiActions, _changeable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, {
    organizations: _emberData.default.hasMany('organization', {
      async: false
    }),
    defaultOrganization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    handle: _emberData.default.attr('string'),
    isAdmin: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    googleRecaptchaToken: _emberData.default.attr('string'),
    rewardfulExternalServiceIdentifier: _emberData.default.attr('string'),
    fetchSession: (0, _emberApiActions.memberAction)({
      path: 'session',
      type: 'get'
    })
  });

  _exports.default = _default;
});