define("trunk/models/subscription", ["exports", "ember-data", "moment", "ember-api-actions", "ember-data-storefront/mixins/loadable-model", "trunk/mixins/models/changeable", "trunk/mixins/models/external-serviceable"], function (_exports, _emberData, _moment, _emberApiActions, _loadableModel, _changeable, _externalServiceable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_changeable.default, _loadableModel.default, _externalServiceable.default, {
    organization: _emberData.default.belongsTo('organization', {
      async: false
    }),
    externalServiceAccount: _emberData.default.belongsTo('external-service-account', {
      async: false
    }),
    paymentMethod: _emberData.default.belongsTo('payment-method', {
      async: false
    }),
    state: _emberData.default.attr('string'),
    stateReason: _emberData.default.attr('string'),
    stateReasonKind: _emberData.default.attr('string'),
    trialEndsAt: _emberData.default.attr('date'),
    pledgedAt: _emberData.default.attr('date'),
    activatedAt: _emberData.default.attr('date'),
    canceledAt: _emberData.default.attr('date'),
    couponCode: _emberData.default.attr('string'),
    planType: _emberData.default.attr('string'),
    planExternalServiceIdentifier: _emberData.default.attr('string'),
    isDisrupted: _emberData.default.attr('boolean'),
    isPledged: _emberData.default.attr('boolean'),
    // Used to contain custom data for requests
    meta: _emberData.default.attr(),
    generatePortalSession: (0, _emberApiActions.memberAction)({
      path: 'portal-sessions',
      type: 'post'
    }),
    isConfirmationRequired: Ember.computed('isPledged', function () {
      return !this.isPledged;
    }),
    isTrialing: Ember.computed('state', function () {
      return this.state == 'trialing';
    }),
    isCanceled: Ember.computed('state', function () {
      // It was never really canceled if it wasn't pledged in the first place
      return this.state == 'canceled' && !Ember.isBlank(this.pledgedAt);
    }),
    isComped: Ember.computed('state', function () {
      return this.state == 'comped';
    }),
    // Returns true if organization has a pledged subscription but is still within the free trial period
    isPledgedTrialing: Ember.computed('isTrialing', 'isPledged', function () {
      return this.isTrialing && this.isPledged;
    }),
    trialRemainingDurationHumanized: Ember.computed('trialEndsAt', function () {
      if (!Ember.isBlank(this.trialEndsAt)) {
        return (0, _moment.default)(this.trialEndsAt).diff((0, _moment.default)(), 'days') + ' days';
      }

      return null;
    })
  });

  _exports.default = _default;
});